import { useAppStore } from '~/store/app'
import { seoApi } from '~/api/seo'
import { useContactStore } from '~/store/contact'
import { IContact } from '~/types/seo'

export const useContact = () => {
  const { $axios, $pinia } = useNuxtApp()
  const store = useAppStore($pinia)
  const { BRAND_CONTACT } = seoApi()
  const { fetchContact } = useContactStore($pinia)
  const contacts = ref<IContact[]>([])

  const fetchBranchContact = async () => {
    try {
      const { data: response } = await $axios.get(BRAND_CONTACT)
      if (response && response.status === 'OK') {
        contacts.value = response.data
      }
    } catch (error) {
      console.log(error)
    }
    return []
  }

  const openLiveChat = async () => {
    const openNewTab = window.open('about:blank', '_blank')

    if (contacts.value.length <= 0) {
      await fetchBranchContact()
    }

    const livechatContact = contacts.value.find((contact: { name: string }) => contact.name === 'livechat')

    if (livechatContact && openNewTab) {
      openNewTab.location.href = livechatContact.url
    } else {
      console.error('Failed to open new tab or livechat contact not found')
    }
  }

  return {
    contacts,
    fetchBranchContact,
    openLiveChat
  }
}
